import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/internal/operators";
import {
  CREATE_NOTE,
  CREATE_DRIVER,
  CREATE_QUOTATION,
  UPDATE_QUOTATION,
  UPDATE_QUOTATION_STATUS,
  CREATE_BILLY,
  UPDATE_POSITIVE_BALANCE_INVOICE,
} from "src/app/@graphql/operations/mutation";
import {
  GET_NOTA_CREDITO,
  GET_NOTA_DEBITO,
  GET_QUOTATION,
  GET_QUOTATIONS,
  GET_QUOTATION_BY_STATUS1,
  GET_QUOTATION_BY_STATUS3,
  GET_QUOTATION_BY_STATUS4,
  GET_BILLY,
  GET_VALIDE_NOTA,
  FIND_QUOTATIONS,
  COUNT_ALL_QUOTATIONS,
  COUNT_QUOTATIONS_BYSTATUS,
  GET_ALL_INVOICES,
  GET_ALL_INVOICES_BYSTATUS,
  GET_ALL_INVOICES_BYSTATUS_INVOICED,
  COUNT_QUOTATIONS_BYSTATUS_INVOICED,
  VALID_BILLY_INVOICES,
  GET_INVOICE_BY_BILLYNUMBER,
  GET_INVOICE_BY_CONTNUMBER,
  VALID_INVOICEPAYMENT_EXITS,
  VALID_CANCELLED_INVOICE,
  VALID_BILLY_NOTES,
} from "src/app/@graphql/operations/query";
import {
  GET_SINGLE_NOTA,
  GET_NOTAS,
  ORDER_CUSTOMER,
  GET_DRIVER,
  GET_BILLY_NOTES,
} from "../../@graphql/operations/query";
import {
  SUBS_NEW_QUOT,
  SUBS_INV_QUOT,
} from "src/app/@graphql/operations/suscription";
import { ApiService } from "src/app/@graphql/services/api.service";
import {
  UPDATE_STATUS_INVOICE,
  CREATE_BILLY_NOTES,
} from "../../@graphql/operations/mutation";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class QuotationsService extends ApiService {
  constructor(
    apollo: Apollo,
    private cookieAuthService: CookieAuthService
  ) {
    super(apollo);
  }

  validBillyNote(note_number: string, year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(VALID_BILLY_NOTES, { note_number, year, company }).pipe(
      map((result: any) => {
        return result.validBillyNoteExists;
      })
    );
  }

  getInvoiceByBillyNumber(invoice_number: string, year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_INVOICE_BY_BILLYNUMBER, {
      invoice_number,
      year,
      company,
    }).pipe(
      map((result: any) => {
        return result.getInvoiceByBillyNumber;
      })
    );
  }

  getInvoiceByContNumber(invoice_number: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_INVOICE_BY_CONTNUMBER, {
      invoice_number,
      company,
    }).pipe(
      map((result: any) => {
        return result.getInvoiceByContNumber;
      })
    );
  }

  validCancelledInvoice(id_invoice: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(VALID_CANCELLED_INVOICE, { id_invoice, company }).pipe(
      map((result: any) => {
        return result.validCancelledInvoice;
      })
    );
  }

  validInvoicePaymentExits(id_invoice: number, id_payment: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(VALID_INVOICEPAYMENT_EXITS, {
      id_invoice,
      id_payment,
      company,
    }).pipe(
      map((result: any) => {
        return result.validInvoicePaymentExits;
      })
    );
  }

  validBillyInvoice(invoice_number: string, year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(VALID_BILLY_INVOICES, {
      invoice_number,
      year,
      company,
    }).pipe(
      map((result: any) => {
        return result.validBillyInvoiceExists;
      })
    );
  }

  listQuotations() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_QUOTATIONS, { company }).pipe(
      map((result: any) => {
        return result.getAllQuotations;
      })
    );
  }

  listQuotationsByStatus1() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_QUOTATION_BY_STATUS1, { company }).pipe(
      map((result: any) => {
        return result.getQuotationsByStatus1;
      })
    );
  }

  listQuotationsByStatus3() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_QUOTATION_BY_STATUS3, { company }).pipe(
      map((result: any) => {
        return result.getQuotationsByStatus3;
      })
    );
  }

  listQuotationsByStatus4() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_QUOTATION_BY_STATUS4, { company }).pipe(
      map((result: any) => {
        return result.getQuotationsByStatus4;
      })
    );
  }

  getQuotation(id_factura: number, companyID?: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const companyId = companyObject?.Id_company;
    const company = companyID ? companyID : companyId;
    return this.query(GET_QUOTATION, { id_factura, company }).pipe(
      map((result: any) => {
        return result.getSingleQuotation;
      })
    );
  }

  createQuotation(
    tax: number,
    id_cliente: number,
    codigo: string[],
    cantidad: number[],
    precio: number[],
    descuento: number[],
    subtotal: number[],
    total: number[],
    id_almacen: number[],
    createdAt: string,
    dueDate: string,
    id_seller: number,
    tax_incl: string,
    id_user: number,
    positive_balance: number,
    observations?: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_QUOTATION, {
      tax,
      id_cliente,
      codigo,
      cantidad,
      precio,
      descuento,
      subtotal,
      total,
      id_almacen,
      createdAt,
      dueDate,
      id_seller,
      tax_incl,
      id_user,
      positive_balance,
      observations,
      company,
    }).pipe(
      map((result: any) => {
        return result.createQuotation;
      })
    );
  }

  updateStatus(
    id_factura: number,
    rejectQ: Boolean,
    deliveryQ: Boolean,
    delivery_date: string,
    invoiceQ: Boolean,
    invoiced_date: string,
    fromLot: boolean,
    id_user: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_QUOTATION_STATUS, {
      id_factura,
      rejectQ: rejectQ,
      deliveryQ: deliveryQ,
      delivery_date: delivery_date,
      invoiceQ: invoiceQ,
      invoiced_date: invoiced_date,
      fromLot: fromLot,
      id_user: id_user,
      company,
    }).pipe(
      map((result: any) => {
        return result.updateStatus;
      })
    );
  }

  updateStatusInvoice(
    id_factura: number,
    pendingSeller: Boolean,
    cancelled: Boolean,
    file: string | null
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_STATUS_INVOICE, {
      id_factura,
      pendingSeller: pendingSeller,
      cancelled: cancelled,
      file: file,
      company,
    }).pipe(
      map((result: any) => {
        return result.updateStatusInvoiced;
      })
    );
  }

  updateQuotation(
    id_factura: number,
    tax: number,
    id_cliente: number,
    id_venta: number[],
    id_ventaDelete: number[],
    codigo: string[],
    cantidad: number[],
    precio: number[],
    descuento: number[],
    subtotal: number[],
    total: number[],
    id_almacen: number[],
    createdAt: string,
    dueDate: string,
    id_seller: number,
    tax_incl: string,
    id_user: number,
    positive_balance: number,
    observations?: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_QUOTATION, {
      id_factura,
      tax,
      id_cliente,
      id_venta,
      id_ventaDelete,
      codigo,
      cantidad,
      precio,
      descuento,
      subtotal,
      total,
      id_almacen,
      createdAt,
      dueDate,
      id_seller,
      tax_incl,
      id_user,
      positive_balance,
      observations,
      company,
    }).pipe(
      map((result: any) => {
        return result.updateQuotation;
      })
    );
  }

  createNote(
    note_fact: number,
    id_warehouse: number[],
    id_articulo: number[],
    codigo: string[],
    cantidad: number[],
    tipo_nota: string,
    concepto: string,
    precio: number[],
    subtotal: number[],
    descuento: number[],
    total: number,
    createAt: string,
    dueDate: string,
    id_user: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_NOTE, {
      note_fact,
      id_warehouse,
      id_articulo,
      codigo,
      cantidad,
      tipo_nota,
      concepto,
      precio,
      subtotal,
      descuento,
      total,
      createAt,
      dueDate,
      company,
      id_user,
    }).pipe(
      map((result: any) => {
        return result.createNote;
      })
    );
  }

  getCredito(note_fact: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_NOTA_CREDITO, { note_fact, company }).pipe(
      map((result: any) => {
        return result.getNotaCredito;
      })
    );
  }

  getDebito(note_fact: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_NOTA_DEBITO, { note_fact, company }).pipe(
      map((result: any) => {
        return result.getNotaDebito;
      })
    );
  }

  getNotes() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_NOTAS, { company }).pipe(
      map((result: any) => {
        return result.getAllNotes;
      })
    );
  }

  getValideNote(note_fact: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_VALIDE_NOTA, { note_fact, company }).pipe(
      map((result: any) => {
        return result.notesForValid;
      })
    );
  }

  getSingleNote(id_nota: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_SINGLE_NOTA, { id_nota, company }).pipe(
      map((result: any) => {
        return result.getSingleNote;
      })
    );
  }

  getOrderCustomer() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(ORDER_CUSTOMER, { company }).pipe(
      map((result: any) => {
        return result.getQuotOrderCustomer;
      })
    );
  }

  subsNewQuot() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.subscription(SUBS_NEW_QUOT, { company }).pipe(
      map((result: any) => {
        return result.newQuotation;
      })
    );
  }

  subsInv() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.subscription(SUBS_INV_QUOT, { company }).pipe(
      map((result: any) => {
        return result.invoiceQuota;
      })
    );
  }

  createDriver(
    invoice: string,
    chofer: string,
    cin: string,
    placa: string,
    permiso_rasda: string,
    destino: string,
    peso_neto: string,
    tasa_c: number,
    son: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_DRIVER, {
      invoice,
      chofer,
      cin,
      placa,
      permiso_rasda,
      destino,
      peso_neto,
      tasa_c,
      son,
      company,
    }).pipe(
      map((result: any) => {
        return result.createDriverV1;
      })
    );
  }

  getDriver(invoice: string, companyID?: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const companyId = companyObject?.Id_company;
    const company = companyID ? companyID : companyId;
    return this.query(GET_DRIVER, { invoice, company }).pipe(
      map((result: any) => {
        return result.getDriverByQuotV1;
      })
    );
  }

  setBillyInvoice(
    id_fact: string,
    id_billy: string,
    invoice_number: string,
    invoiced_date: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_BILLY, {
      id_fact,
      id_billy,
      invoice_number,
      invoiced_date,
      company,
    }).pipe(
      map((result: any) => {
        return result.saveBillyInvoice;
      })
    );
  }

  getBillyInvo(id_fact: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_BILLY, { id_fact, company }).pipe(
      map((result: any) => {
        return result.getBillyInvoice;
      })
    );
  }

  setBillyNote(id_note: string, id_billy: string, note_number: string, fromLot: boolean) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_BILLY_NOTES, {
      id_note,
      id_billy,
      note_number,
      fromLot,
      company,
    }).pipe(
      map((result: any) => {
        return result.saveBillyNote;
      })
    );
  }

  getBillyNote(id_note: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_BILLY_NOTES, { id_note, company }).pipe(
      map((result: any) => {
        return result.getBillyNote;
      })
    );
  }

  //Pagination
  countAllQuotations() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(COUNT_ALL_QUOTATIONS, { company }).pipe(
      map((result: any) => {
        return result.countAllQuotations;
      })
    );
  }

  countQuotationsByStatus(estado: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(COUNT_QUOTATIONS_BYSTATUS, { estado, company }).pipe(
      map((result: any) => {
        return result.countQuotationsByStatus;
      })
    );
  }

  countQuotationsByStatusInvoiced(estado_factura: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(COUNT_QUOTATIONS_BYSTATUS_INVOICED, {
      estado_factura,
      company,
    }).pipe(
      map((result: any) => {
        return result.countQuotationsByStatusInvoiced;
      })
    );
  }

  findQuotations(estado: number, value: string, size: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(FIND_QUOTATIONS, { estado, value, size, company }).pipe(
      map((result: any) => {
        return result.findQuotations;
      })
    );
  }

  getAllInvoices(page: number, size: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_INVOICES, { company, page, size }).pipe(
      map((result: any) => {
        return result.getAllInvoices;
      })
    );
  }

  getAllInvoicesByStatus(estado: number, page: number, size: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_INVOICES_BYSTATUS, {
      company,
      estado,
      page,
      size,
    }).pipe(
      map((result: any) => {
        return result.getAllInvoicesByStatus;
      })
    );
  }

  getAllInvoicesByStatusInvoiced(
    estado_factura: number,
    page: number,
    size: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_INVOICES_BYSTATUS_INVOICED, {
      company,
      estado_factura,
      page,
      size,
    }).pipe(
      map((result: any) => {
        return result.getAllInvoicesByStatusInvoiced;
      })
    );
  }

  updatePositiveBalanceInvoice(id_factura: number, positiveBalance: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_POSITIVE_BALANCE_INVOICE, {
      id_factura: id_factura,
      positive_balance: positiveBalance,
      company: company,
    }).pipe(
      map((result: any) => {
        return result.updatePositiveBalanceInvoice;
      })
    );
  }
}
